import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import liff from "@line/liff";
import {httpClient} from "../../core/HttpClient";
import {getDatabaseInstance} from "../../core/Firebase";
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";

export default function LandingPage(props) {
    const history = useHistory();
    console.log('LandingPage');

    useEffect(() => {
        console.log('[LandingPage]');
        try {

            const urlParams = new URLSearchParams(window.location.search);
            const cid = urlParams.get('cid');
            const code = urlParams.get('code');
            console.log('cid', cid);
            console.log('code', code);
            const isLineBrowser = liff.isInClient();
            const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            liff.init({liffId: liffId, withLoginOnExternalBrowser: false}).then(r=>{
                if(isLineBrowser){
                    liff.getProfile()
                        .then((profile) => {
                            const displayName = profile.displayName;
                            const pictureUrl = profile.pictureUrl;
                            if(cid){
                                let token = localStorage.getItem(`token`);
                                if(token){
                                    history.push(`/store/${cid}/s`);
                                } else {
                                    const app = getDatabaseInstance(cid);

                                    const url = process.env.REACT_APP_API_BASE_URL + `/ducks/ticket/${cid}`;
                                    httpClient.post(url, {})
                                        .then(res => {
                                            if(res.data){

                                                let key = 'ducktokens/'+res.data;
                                                const db = getDatabase(app);
                                                const auth = getAuth(app);
                                                signInAnonymously(auth)
                                                    .then(() => {

                                                        const statusRef = ref(db, key);
                                                        onValue(statusRef, (snapshot) => {
                                                            const data = snapshot.val();
                                                            if(data){
                                                                localStorage.setItem(`token`, data.token);
                                                                let customer = {
                                                                    displayName: displayName,
                                                                    pictureUrl: pictureUrl
                                                                };
                                                                localStorage.setItem('customer', JSON.stringify(customer));
                                                                history.push(`/store/${cid}/s`);
                                                            }
                                                        }, (error) => {
                                                            console.error(error);
                                                        });

                                                        liff.sendMessages([
                                                            {
                                                                type: "text",
                                                                text: `สวัสดี ${displayName}, ลูกค้าใหม่[${res.data}]`,
                                                            },
                                                        ]).then(() => {
                                                            console.log("message sent");
                                                        }).catch((err) => {
                                                            console.log("error send message", err);
                                                        });

                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            }
                                        });
                                }

                            }
                        });
                } else {
                    if(!liff.isLoggedIn()){
                        const redirectUri = window.location.href;
                        liff.login({redirectUri: redirectUri});
                    } else {
                        liff.getProfile()
                            .then((profile) => {
                                let token = localStorage.getItem(`token`);
                                if(token){
                                    history.push(`/store/${cid}/s`);
                                } else {

                                    history.push(`/refresh`);

                                    // const url = process.env.REACT_APP_API_BASE_URL + '/customers/token';
                                    // httpClient.post(url, {lineUserId: profile.userId})
                                    //     .then(res => {
                                    //         let data = res.data;
                                    //         if(data.token){
                                    //             localStorage.setItem('token', data.token);
                                    //             // let customer = {
                                    //             //     displayName: data.displayName,
                                    //             //     pictureUrl: data.pictureUrl
                                    //             // };
                                    //             // localStorage.setItem('customer', JSON.stringify(customer));
                                    //
                                    //             history.push(`/store/${cid}/s`);
                                    //
                                    //         } else {
                                    //             localStorage.removeItem('token');
                                    //             localStorage.removeItem('customer');
                                    //         }
                                    //     });

                                }

                            });
                    }
                }
            }).catch(err => {
                throw err
            });



        } catch (e){
            console.log(e);
        }
    }, [history, props]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    );
}
