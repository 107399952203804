import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ProductViewDialog from "../../Product/ProductControlDialog";
import {httpClient} from "../../../core/HttpClient";
import CartUtil from "../../../core/CartUtil";
import {getCache} from "../../../core/LocalStorageUtil";
import {Context, StoreContext} from "../../../core/Context";
import {TextI18n} from "../../TextI18n";
import PriceUtil from "../../../core/PriceUtil";
import TextField from "@mui/material/TextField";
import CartDiscount from "../CartDiscount";

const shapeStyles = { border: '1px solid grey', padding: '0 8px 0 8px', fontSize: '0.8rem' };

const itemOptionalInfoBox = (item) => {
    let items = [];
    if(item.optional){
        Object.keys(item.optional).forEach(key=>{
            if(key !== 'total'){
                if(item.optional[key].checkedOption){
                    item.optional[key].checkedOption.forEach(checkedOption=>{
                        if(checkedOption){
                            items.push({name: checkedOption.name, price: checkedOption.price * item.qty});
                        }
                    })
                }
            }
        });
    }
    return (
        <Box>
            {
                items.map((item, i) => (
                    <Box key={`optional-${i}`} sx={{ color: 'text.secondary' }} display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"}>
                            <CheckBoxIcon sx={{ fontSize: 15, marginTop: '5px' }} />
                            <Box sx={{ marginLeft: '3px' }}><TextI18n value={item.name} lines={2}></TextI18n></Box>
                        </Box>
                        <Box display={"flex"} justifyContent={"flex-end"} style={{minWidth: '70px'}}>
                            <Typography variant="subtitle2">
                                <NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                            </Typography>
                        </Box>
                    </Box>
                ))
            }
        </Box>

    );
};

const itemRemarkBox = (item) => {
    if(item.remark){
        return (
            <Box mt={1} mb={1}>
                <Typography variant={"caption"} style={{color: 'red'}}>
                    {item.remark}
                </Typography>
            </Box>);
    } else {
        return null;
    }
};

const renderDeliveryPriceBox = (deliverType, total, discount, discountFlag, deliveryPrice) => {
    let result = new PriceUtil().getDeliveryPrice(deliverType, total, discount, discountFlag, deliveryPrice);
    if(result < deliveryPrice){
        return (
            <Box display={"flex"} alignItems={"baseline"}>
                <Box mr={1}>
                    <Typography variant={"body1"} style={{textDecoration: "line-through"}}>
                        <NumberFormat
                            value={deliveryPrice}
                            displayType={'text'}
                            thousandSeparator={true} prefix={'฿'}/>
                    </Typography>
                </Box>
                <NumberFormat
                    value={result}
                    displayType={'text'}
                    thousandSeparator={true} prefix={'฿'}/>
            </Box>
        );
    } else {
        return (
            <NumberFormat
                value={result}
                displayType={'text'}
                thousandSeparator={true} prefix={'฿'}/>);
    }

};


const renderGrandTotalPriceBeforeDiscount = (deliverType, total, discount, discountFlag, deliveryPrice) => {

    let result = new PriceUtil().getGrandTotalPriceBeforeDiscount(deliverType, total, discount, discountFlag, deliveryPrice);
    return (
        <NumberFormat
            value={result}
            displayType={'text'}
            thousandSeparator={true} prefix={'฿'}/>);
};

const renderGrandTotalPriceBox = (deliverType, total, discount, discountFlag, deliveryPrice) => {
    let result = new PriceUtil().getGrandTotalPriceAfterDiscount(deliverType, total, discount, discountFlag, deliveryPrice);
    return (
        <NumberFormat
            value={result}
            displayType={'text'}
            thousandSeparator={true} prefix={'฿'}/>);
};

const MyProductItem = (({product, i, t, onClick}) => {
    return (
        <>
            {product &&
                product.items.map((item, j) => (
                    <ListItem divider={true} key={`${product._id}-${i}-${j}`} alignItems="flex-start"
                              onClick={e=>onClick(product, product.items, {...item, index: j})}>
                        <ListItemAvatar>
                            <Avatar variant="square">
                                <img src={product.images[0]?`${process.env.REACT_APP_CDN_BASE_URL}/${product.images[0].permission}/merchant/s/${product.images[0].name}`
                                    : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}
                                     alt='product'
                                     style={ {height: '100%', border: 0, objectFit: 'cover'} } />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{wordBreak: 'break-word'}}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography component={'span'} variant="body1">
                                    <TextI18n value={product.name} ></TextI18n>
                                </Typography>
                                <Box display={"flex"} justifyContent={"flex-end"} style={{minWidth: '80px'}}>
                                    <Typography variant="subtitle2">
                                        <NumberFormat value={product.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                                    </Typography>
                                </Box>
                            </Box>
                            {itemOptionalInfoBox(item)}
                            {itemRemarkBox(item)}
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Link sx={{fontSize: '0.85rem'}}>{t('common.edit')}</Link>
                                <Box display={"flex"} justifyContent={"flex-start"}>
                                    <Box sx={shapeStyles}>x{item.qty}</Box>
                                </Box>
                            </Box>
                        </ListItemText>
                    </ListItem>
                ))
            }
        </>
    )
});

function _distance(lat1,
                   lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}

export default function CartList({mode, shop, deliver}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [storeContext] = useContext(StoreContext);
    const [cart, setCart] = useState({products: []});
    const [remark, setRemark] = useState('');
    const [product, setProduct] = useState({});
    const [item, setItem] = useState({});
    const [open, setOpen] = useState({loading: false, dialog: false});
    const [likeProduct] = useState({});

    const loadCart = useCallback((callback) => {

        let myCart = {outOfDelivery: 0, total: 0, qty: 0, products: {}, remark: ''};
        let cacheData = getCache(cid);
        if(!cacheData.cart){
            return history.push({
                pathname: `/store/${cid}/s`,
                historyData: {showFoodOrder: false}
            });
        }

        if(cacheData.cart.qty === 0){
            history.push({
                pathname: `/store/${cid}/s`,
                historyData: {showFoodOrder: false}
            });
        }else {
            myCart = cacheData.cart;
        }

        if(cacheData.location){
            const cd = _distance(cacheData.location.coordinates.lat, shop.lat,
                cacheData.location.coordinates.lng, shop.lng);
            let outOfDelivery = 0;
            if(cd > shop.distance){
                outOfDelivery = 1;
            }
            myCart.outOfDelivery = outOfDelivery;
        }

        let keys = Object.keys(myCart.products);
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/products/list';
        let data = {
            productIds: keys
        };

        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    let products = [];
                    let newCart = {
                        qty: myCart.qty,
                        total: myCart.total,
                        remark: myCart.remark
                    };
                    let deliverPrice = 0;
                    res.data.forEach(p=>{
                        let cp = myCart.products[p._id];

                        if(p.deliverPrice>0){
                            if(p.deliverLimit>0){
                                let count = Math.ceil(cp.qty/p.deliverLimit);
                                deliverPrice = p.deliverPrice * count;
                            } else {
                                deliverPrice = p.deliverPrice;
                            }
                        }

                        products.push({_id: p._id, name: p.name, description: p.description, detail: p.detail,
                            images: p.images, items: cp.items, qty: cp.qty, price: p.price, options: p.options,
                            remaining: p.remaining, deliverPrice: p.deliverPrice, deliverLimit: p.deliverLimit, open: p.open});
                    });
                    newCart.products = products;
                    newCart.deliverPrice = deliverPrice;
                    setCart(newCart);
                    setRemark(newCart.remark);

                    myCart.deliverPrice = deliverPrice;
                }

                if(shop.discount){
                    let discount = shop.discount;
                    let foodPrice = myCart.total;
                    let myDiscount = {
                        offer: {
                            minPrice: 0,
                            food: 0,
                            delivery: 0
                        },
                        food: 0,
                        delivery: 0,
                    };

                    if(foodPrice > 0){
                        let _minPrice = Number.MAX_SAFE_INTEGER;

                        if(discount && discount.food === 'discount' && discount.foodRates[0]){
                            let rate = discount.foodRates[0];
                            if(foodPrice >= rate.price){
                                myDiscount.food = rate.discount;
                            } else {
                                let difPrice = (rate.price - foodPrice);
                                if(difPrice <= _minPrice){
                                    _minPrice = difPrice;
                                    myDiscount.offer.food = rate.discount;
                                }
                            }
                        }

                        if(discount && discount.delivery === 'discount' && discount.deliveryRates[0]){
                            let rate = discount.deliveryRates[0];
                            if(rate.discount > 0){
                                let difPrice = (rate.price - foodPrice);
                                if(foodPrice >= rate.price){
                                    myDiscount.delivery = rate.discount;
                                } else if(difPrice === _minPrice) {
                                    _minPrice = difPrice;
                                    myDiscount.offer.delivery = rate.discount;
                                } else if(difPrice < _minPrice) {
                                    _minPrice = difPrice;
                                    myDiscount.offer.food = 0;
                                    myDiscount.offer.delivery = rate.discount;
                                }
                            }
                        }

                        if(_minPrice < Number.MAX_SAFE_INTEGER){
                            myDiscount.offer.minPrice = _minPrice;
                        }
                    }

                    myCart.discount = myDiscount;
                } else {
                    myCart.discount = {
                        offer: {
                            minPrice: 0,
                            food: 0,
                            delivery: 0
                        },
                        food: 0,
                        delivery: 0,
                    };
                }

                callback(myCart);
            }).catch(e=>{
                console.log(e);
                callback();
            })

    }, [shop, cid, history]);

    useEffect(() => {
        console.log('[CartList]');
        loadCart(r=>{
            // console.log('rr', r);
            if(r){
                let discount = r.discount;
                let delivery = 0;
                if(storeContext && storeContext.deliverPrice){
                    delivery = storeContext.deliverPrice.price;
                }

                if(delivery > 0){
                    if(discount.offer.delivery > 0){
                        if(delivery < discount.offer.delivery){
                            discount.offer.delivery = delivery;
                        }
                    }
                    if(discount.delivery > 0){
                        if(delivery < discount.delivery){
                            discount.delivery = delivery;
                        }
                    }
                } else {
                    discount.offer.delivery = 0;
                    discount.delivery = 0;
                }

                if(discount.offer.food+discount.offer.delivery <= 0){
                    discount.offer.minPrice = 0;
                }

                setContext({qty: r.qty, paymentAmount: r.total, deliveryPrice: delivery, discount: discount});
            }
        });
    }, [shop, loadCart, setContext, storeContext]);

    const handleClickItem = (product,productItems, item)=>{
        let cartQty = 0;
        if(productItems){
            productItems.forEach((mi,i)=>{
                if(item.index !== i){
                    cartQty+=mi.qty;
                }
            })
        }
        setProduct({...product, cartQty: cartQty});
        setItem(item);
        setOpen({loading: false, dialog: true});
    };

    const handleChange = (value) => {
        if(value.mode === 0){
            // update
            new CartUtil(cid).updateCart(value.product, value.item);
        } else if(value.mode === 1){
            // add
            new CartUtil(cid).addToCart(value.product, value.item);
        } else if(value.mode === -1){
            // remove
            new CartUtil(cid).removeCartItem(value.product, value.item);
        }

        loadCart(r=>{
            setOpen({loading: false, dialog: false});
            // console.log('r', r);
            if(context){
                // not use context;
            }
            if(r){
                let discount = r.discount;
                let delivery = 0;
                if(storeContext && storeContext.deliverPrice){
                    delivery = storeContext.deliverPrice.price;
                }
                if(discount && delivery < discount.delivery){
                    discount.delivery = delivery;
                }
                setContext({qty: r.qty, paymentAmount: r.total, deliveryPrice: delivery, discount: discount});
            }
        });
    };

    const handleClose = () => {
        setOpen({loading: false, dialog: false});
    };

    const handleAddMoreItem = () => {
        history.push(`/store/${cid}/s`);
    };

    const handleRemark = ({target}) => {
        new CartUtil(cid).updateCartRemark(target.value);
        setRemark(target.value);
    };

    return (
        <div>
            <Card sx={{borderRadius: '0px'}} pt={2}>
                <CardHeader style={{paddingBottom: '5px'}} title={<Typography variant="h6" component="div">
                    {t('cart.myOrder')}
                </Typography>} />
                {cart.products.length > 0 &&
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {cart.products.map((product, i) => (
                        <MyProductItem key={`item-${product._id}-${i}`} product={product} i={i} t={t} onClick={handleClickItem} />
                    ))}
                </List>
                }
                <CardContent>
                    <Box mb={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            fullWidth={true}
                            onClick={handleAddMoreItem} >
                            {t('cart.addAnother')}
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <div>{t('cart.food')}</div>
                        <div><NumberFormat value={cart.total} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿'}/></div>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    {mode === 'o' &&
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center"
                                 style={{width: '100%'}}>
                                <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                                    <span>{t('cart.delivery')}</span>
                                    {storeContext.deliverType==='DELIVERY' && <Typography variant="body1" color="textSecondary" sx={{fontSize: '0.85rem', marginLeft: '3px'}}>({deliver.distance} {t('cart.distanceUnit')})</Typography>}
                                </Box>
                                <div>
                                    {renderDeliveryPriceBox(storeContext.deliverType, cart.total, shop.discount,mode === 'o', cart.deliverPrice + deliver.price)}
                                </div>
                            </Box>
                            <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        </>
                    }
                    <CartDiscount mode={mode} />
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         fontWeight="fontWeightBold"
                         style={{width: '100%'}} mt={2}>
                        <div>{t('common.grandTotal')}</div>
                        <Box display={"flex"} alignItems={"baseline"}>
                            {mode === 'o' && context.discount && (context.discount.food + context.discount.delivery) > 0 &&
                                <Box mr={1}>
                                    <Typography variant={"body1"} style={{textDecoration: "line-through"}}>
                                        {renderGrandTotalPriceBeforeDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, mode === 'o', context.deliveryPrice)}
                                    </Typography>
                                </Box>
                            }
                            {renderGrandTotalPriceBox(storeContext.deliverType, cart.total, shop.discount, mode === 'o', cart.deliverPrice + deliver.price)}
                        </Box>

                    </Box>
                    <Box mt={2}>
                        <form autoComplete="false">
                            <TextField label={t('cart.remark')}
                                       fullWidth={true}
                                       multiline
                                       rows={2}
                                       name="remark"
                                       value={remark}
                                       type="text"
                                       InputLabelProps={{
                                           shrink: remark?true:false,
                                       }}
                                       onChange={handleRemark}/>
                        </form>
                    </Box>
                </CardContent>
            </Card>
            <ProductViewDialog
                shop={shop}
                product={product}
                item={item}
                likeProduct={likeProduct}
                open={open.dialog}
                onChange={handleChange}
                onClose={handleClose}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
}
