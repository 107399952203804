import React from 'react';
import { Router } from 'react-router';
import {Redirect, Switch} from 'react-router-dom';
import Cart from './components/Cart';
import CancellationPolicy from './components/HomePage/CancellationPolicy';
import Login from './components/Login';
import HomePage from './components/HomePage';
import ContactUs from './components/HomePage/ContactUs';
import CustomerLogin from './components/CustomerConsole/CustomerLogin';
import CustomerSignIn from './components/CustomerConsole/CustomerSignIn';
import MyInformation from './components/Profile/MyInformation';
import OrdersHistory from './components/Profile/OrdersHistory';
import OrdersInfo from './components/Profile/OrdersInfo';
import Inside from './layouts/Inside';
import Blank from './layouts/Blank';
import Default from './layouts/Default';
import OrderingLayout from './layouts/OrderingLayout';
import OnlineOrdersPage from "./components/OnlineOrdersPage";
import Terms from "./components/HomePage/Terms";
import Refresh from "./components/Refresh";
import ErrorPage from "./components/ErrorPage";
import Privacy from "./components/HomePage/Privacy";
import Documents from "./components/HomePage/Document";
import StandardPackage from "./components/HomePage/Document/standardPackage";
import MenuOnlyPackage from "./components/HomePage/Document/menuonlyPackage";
import LandingPage from "./components/LandingPage";

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Default path="/cancellation" component={CancellationPolicy} />
            <Default path="/contact" component={ContactUs} />
            <Default path="/documents/menu" component={MenuOnlyPackage} />
            <Default path="/documents/standard" component={StandardPackage} />
            <Default path="/documents" component={Documents} />
            <Default path="/terms" component={Terms} />
            <Default path="/privacy" component={Privacy} />
            {/*<Console path="/hm/c/orders" component={CustomerOrders} />*/}
            {/*<Console path="/hm/c/following" component={CustomerShopHistory} />*/}
            {/*<Console path="/hm/c/order/:uid" component={CustomerOrdersInfo} />*/}
            {/*<Console path="/hm/c/mobile/:cid/:uid" component={CustomerMobileOrdersInfo} />*/}
            {/*Need /hm/login because LINE Liff login (Callback URL (LINE Login) must start with same prefix of liff Endpoint URL (/hm) */}
            {/*<Blank path="/hm/login" component={Login} />*/}
            {/*<BlankWithAuthLayout path="/hm/:mode/:cid/:uid/i" component={OrdersInfo} title="profile.ordersInformation" />*/}
            {/*<OrderingLayout path="/hm/:mode/:cid/review" component={ReviewPage} />*/}
            {/*<OrderingLayout path="/hm/:mode/:cid/reward" component={RewardPage} />*/}
            {/*<OrderingLayout path="/hm/:mode/:cid/profile" component={MyInformation} />*/}
            {/*<OrderingLayout path="/hm/m/:cid/c" component={CartMobileOrder} title="cart.myCart" backTo="ordering" />*/}
            {/*<OrderingLayout path="/hm/m/:cid/p/:uid" component={MobileOrderPaymentPage} title="cart.myCart" backTo="ordering" />*/}
            {/*<OrderingLayout path="/hm/o/:cid" component={OnlineOrdersPage} />*/}
            {/*<OrderingLayout path="/hm/m/:cid" component={MobileOrdersPage} />*/}
            {/*<OrderingLayout path="/hm/v/:cid" component={ViewMenuPage} />*/}
            {/*<OrderingLayout path="/hm/r/:cid" component={RecommendPage} />*/}
            <OrderingLayout path="/store/:cid/c" component={Cart} title="cart.myCart" backTo="ordering" />
            <OrderingLayout path="/store/:cid/s" component={OnlineOrdersPage} />
            <Blank path="/store/:cid" component={LandingPage} />
            <Blank path="/store" component={LandingPage} />
            {/*<Route path="/hm/1/:cid" component={QRLandingPage} />*/}
            {/*<PublishSlim path="/hm/:cid" component={Home} />*/}
            {/*<Route path="/hm" component={LandingPage} />*/}
            <Inside path="/p/:cid" component={MyInformation} title="profile.myInformation" backTo="store" />
            <Inside path="/p" component={MyInformation} title="profile.myInformation" backTo="store" />
            <OrderingLayout path="/h/:cid/:uid" component={OrdersInfo} title="profile.ordersHistory" backTo="history" />
            <OrderingLayout path="/h/:cid" component={OrdersHistory} title="profile.ordersHistory" backTo="store" />
            <Blank path="/c/login" component={CustomerLogin} />
            <Blank path="/c/signin" component={CustomerSignIn} />
            <Blank path="/refresh" component={Refresh} />
            <Blank path="/error" component={ErrorPage} />
            {/*<Blank path="/:mode/login" component={Login} />*/}
            <Default path="/" component={HomePage} />
            <Redirect to="/" />
        </Switch>
    </Router>
);

export default Routes;