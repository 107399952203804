import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import NumberFormat from 'react-number-format';
import {ProductView} from "../ProductView";
import NotifyDialog from "../../NotifyDialog";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductControlDialog({mode, open, shop, product, item, likeProduct, onChange, onClose}) {
    const { t } = useTranslation();
    const [state, setState] = useState({index: -1, remark: '', qty: 1, optional: {total: 0}});
    const [requireOptional, setRequireOptional] = useState(false);
    const [remark, setRemark] = useState('');
    const disableOrdering = shop.state === 'temporarily-close'
        || (shop.state === 'close' && shop.orderingType === 'inorder')
        || (shop.state === 'close' && mode === 'm')
        || (mode === 'o' && !shop.ordering);

    useEffect(() => {
        console.log('[ProductControlDialog]');
        let requireOpt = false;
        if(product.options){
            product.options.forEach(opt=>{
                if(opt.optionalRequire){
                    if(item.optional){
                        if(!item.optional[opt._id]){
                            requireOpt = true;
                        } else if(item.optional[opt._id].checked.length === 0){
                            requireOpt = true;
                        }
                    }
                }
            });
        }

        setRemark(item.remark);
        setRequireOptional(requireOpt);
        setState(item);
    }, [product, item]);

    const handleRemarkChange = (value) => {
        setRemark(value)
    };

    const handleLikeChange = (value) => {
        onChange({mode: 2, product: value.product, like: value.like, likeCount: value.likeCount});
    };

    const handleOptionalChange = (value) => {
        let newState = {...state, optional: value};
        setState(newState);

        let requireOpt = false;
        if(product.options){
            product.options.forEach(opt=>{
                if(opt.optionalRequire){
                    if(!value[opt._id]){
                        requireOpt = true;
                    } else if(value[opt._id].checked.length === 0){
                        requireOpt = true;
                    }
                }
            });
        }

        setRequireOptional(requireOpt);
    };

    const handleIncrease = () => {
        if(product.remaining <= state.qty){
            return;
        }
        let newState = {...state, qty: ++state.qty};
        setState(newState);
    };

    const handleDecrease = () => {
        let newState = {...state, qty: --state.qty};
        setState(newState);
    };

    const handleAdd = () => {
        onChange({mode: 1, product: product, item: {...state, remark: remark}});
        setRemark('');
    };

    const handleUpdate = () => {
        onChange({mode: 0, product: product, item: {...state, remark: remark}});
    };

    const handleRemove = () => {
        onChange({mode: -1, product: product, item: {...state, remark: remark}});
    };

    return (
        <Dialog fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}>
            <ProductView mode={mode}
                         edit={state.index>-1}
                         disableOrdering={disableOrdering}
                         shopOrderingType={shop.orderingType}
                         product={product}
                         item={item}
                         likeProduct={likeProduct}
                         onLikeChange={handleLikeChange}
                         onRemarkChange={handleRemarkChange}
                         onOptionalChange={handleOptionalChange}
                         onIncrease={handleIncrease}
                         onDecrease={handleDecrease}
                         onClose={onClose}
            />
            {mode !== 'v' &&
                <Box style={{
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        paddingBottom: '10px',
                        background: '#fff',
                        borderRadius: '4px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                        overflow: 'hidden',
                        zIndex: 999
                    }}>
                    {disableOrdering &&
                        <Button style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                                variant="contained"
                                onClick={handleAdd}
                                size="large"
                                color="primary"
                                disabled={true}
                                fullWidth={true}
                                disableElevation autoFocus>
                            <Box display="flex" justifyContent="center" alignItems="center"
                                 style={{width: '100%'}}>
                                <div>
                                    {t((shop.state === 'temporarily-close' || !shop.ordering) ? 'cart.stopOrdering' : 'shop.closed')}
                                </div>
                            </Box>
                        </Button>
                    }
                    {!disableOrdering &&
                        <>
                            {!product.oos &&
                                <>
                                    {mode !== 'm' && shop.orderingType === 'preorder' && shop.state === 'close' &&
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1} mb={1}>
                                            <Typography variant="caption">
                                                * {t('cart.preordersMessage')}
                                            </Typography>
                                            <NotifyDialog />
                                        </Box>
                                    }
                                </>
                            }
                            {product.oos &&
                                <Box mb={1}>
                                    <Button style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disabled={true}
                                            fullWidth={true}
                                            disableElevation autoFocus>
                                        <div>{t('product.outOfStock')}</div>
                                    </Button>
                                </Box>
                            }
                            {!product.oos &&
                                <Box mb={1}>
                                    {state.index < 0 &&
                                        <Button style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                                                variant="contained"
                                                onClick={handleAdd}
                                                size="large"
                                                color="primary"
                                                disabled={requireOptional}
                                                fullWidth={true}
                                                disableElevation autoFocus>
                                            <Box display="flex" justifyContent="space-between" alignItems="center"
                                                 style={{width: '100%'}}>
                                                <div>
                                                    {requireOptional ? t('cart.selectRequireOption') : shop.state === 'close' ? t('cart.preorders') : t('cart.addToCart')}
                                                </div>
                                                <strong><NumberFormat value={(product.price + state.optional.total) * state.qty}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true} prefix={'฿'}/></strong>
                                            </Box>
                                        </Button>
                                    }
                                    {state.index > -1 && state.qty > 0 &&
                                        <Button style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                                                variant="contained"
                                                onClick={handleUpdate}
                                                size="large"
                                                color="primary"
                                                disabled={requireOptional}
                                                fullWidth={true}
                                                disableElevation autoFocus>
                                            <Box display="flex" justifyContent="space-between" alignItems="center"
                                                 style={{width: '100%'}}>
                                                <div>{t('common.withSave')}</div>
                                                <strong><NumberFormat value={(product.price + state.optional.total) * state.qty}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true} prefix={'฿'}/></strong>
                                            </Box>
                                        </Button>
                                    }
                                    {state.index > -1 && state.qty < 1 &&
                                        <Button style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                                                variant="contained"
                                                onClick={handleRemove}
                                                size="large"
                                                color="secondary"
                                                fullWidth={true}
                                                disableElevation autoFocus>
                                            <div>{t('common.remove')}</div>
                                        </Button>
                                    }
                                </Box>
                            }
                        </>
                    }
                </Box>
            }
        </Dialog>
    )
};