import React, {useState, useEffect, useContext, useCallback} from 'react';
import { useTranslation } from "react-i18next";
import {useHistory, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CartList from "./CartList";
import PaymentChannel from "./../Payment/PaymentChannel";
import {httpClient} from "../../core/HttpClient";
import ConfirmOrder from "../ConfirmOrder";
import {getCache, saveCache} from "../../core/LocalStorageUtil";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeliverSelection from "../DeliverSelection";
import Address from "../Location/Address";
import TimeSelection from "../TimeSelection";
import {Collapse} from "@mui/material";
import ContactProfile from "../ContactProfile";
import {StoreContext} from "../../core/Context";
import Card from "@mui/material/Card";
import Location from "../Location/Location";
import CartDiscountNotify from "./CartDiscountNotify";
import qs from "qs";

const shapeStyles = { backgroundColor: 'orange.main', padding: '0 8px 0 8px', color: 'orange.contrastText', fontSize: '0.8rem' };

export default function Cart(props) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [state, setState] = useState({name: {}, image:{}, verified: true, deliveryAreas: [], orderingType: 'inorder'});
    const [initial, setInitial] = useState(0);
    const [storeContext] = useContext(StoreContext);
    let cacheData = getCache(cid);
    let cart = cacheData.cart;
    const uid = qs.parse(props.location.search, { ignoreQueryPrefix: true }).uid;
    const loadCartFunction = useCallback(() => {
        return new Promise((resolve, reject) => {
            if(uid){
                httpClient.get(process.env.REACT_APP_API_BASE_URL + `/secure/customer/cart/${cid}/${uid}`).then(res => {
                        if(res.data){
                            resolve(res.data);
                        } else {
                            resolve({status: 202});
                        }
                    }).catch(e=>{
                    resolve({status: 202});
                });
            } else {
                resolve({status: 202})
            }
        });
    }, [cid, uid]);

    useEffect(() => {
        let token = localStorage.getItem(`token`);
        console.log('[Cart]');
        if(token){

            let tasks = [
                httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
                httpClient.get(process.env.REACT_APP_API_BASE_URL + `/secure/customer/profile`),
                loadCartFunction()
            ];
            Promise.all(tasks)
                .then(rs=>{
                    if(rs){
                        if(rs[0].data){
                            setState(rs[0].data);
                        }

                        if(!rs[1].data){
                            history.push(`/store/${cid}/s`);
                        }

                        if(rs[2].result){
                            saveCache(rs[2].result);
                        }
                    }

                    setInitial(1);
                });
        } else {

        }
    }, [cid, uid, history, loadCartFunction]);

    const handleBack = () => {
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/store/${_cid}/s`);
    };

    return (
        <div>
            {initial === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {initial === 1 &&
            <>
                <header>
                    <AppBar elevation={0}
                            color="light"
                            position="static"
                            sx={{borderBottom: '1px solid #e7e7e7'}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit"
                                        onClick={handleBack}
                                        aria-label="menu" sx={{ mr: 2 }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Box display="flex" justifyContent="center">
                                    {t('cart.myCart')}
                                </Box>
                            </Typography>
                            {cart &&
                                <Box sx={shapeStyles}>{cart.qty}</Box>
                            }
                        </Toolbar>
                    </AppBar>
                </header>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <CartList mode="o" shop={state} deliver={storeContext.deliverPrice || {price: 0, additionalPrice: 0}} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{borderRadius: '0px'}} pt={2}>
                            <Box m={2} mt={3}>
                                <Box display={"flex"} alignItems={"center"}><Typography variant="h6" component="div">
                                    {t('deliver.selectMethod')}
                                    </Typography>{storeContext.errors.indexOf('deliverType') > -1 && <Box ml={1}><Typography variant="subtitle1" color={"red"} component="div">
                                        ({t('common.message.pleaseSelect')})
                                    </Typography></Box>}
                                </Box>
                                <Box>
                                    <DeliverSelection shop={state} />
                                </Box>
                            </Box>
                            <Collapse in={storeContext.deliverType === 'PICKUP'} >
                                <Box m={2} mt={0}>
                                    <Typography variant="h6" component="div">
                                        {t('profile.contact')}
                                    </Typography>
                                    <Box mt={1} mb={2}>
                                        <ContactProfile shop={state} />
                                    </Box>
                                </Box>
                            </Collapse>
                            <Collapse in={storeContext.deliverType === 'DELIVERY'} >
                                <Box m={2} mt={0} mb={3}>
                                    <Typography variant="h6" component="div">
                                        {t('address.location')}
                                    </Typography>
                                    <Box mt={1} mb={2}>
                                        <Location shop={state} />
                                    </Box>
                                </Box>
                            </Collapse>
                            <Collapse in={storeContext.deliverType === 'PARCEL'} >
                                <Box m={2} mt={0} mb={3}>
                                    <Typography variant="h6" component="div">
                                        {t('address.delivery')}
                                    </Typography>
                                    <Box mt={1} mb={2}>
                                        <Address shop={state} />
                                    </Box>
                                </Box>
                            </Collapse>
                            {['PICKUP', 'DELIVERY'].indexOf(storeContext.deliverType) > -1
                                && ((state.orderingType === 'preorder' && state.state === 'close')
                                    || state.deliveryType === 'round'
                                    || storeContext.deliverType === 'PICKUP') &&
                                <Box m={2} mt={1}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography variant="h6" component="div">
                                            {storeContext.deliverType === 'DELIVERY' && t('address.specifyRoundTime')}
                                            {storeContext.deliverType !== 'DELIVERY' && t('address.specifyTime')}
                                        </Typography>
                                        {storeContext.errors.indexOf('deliverTime') > -1 &&
                                            <Box ml={1}>
                                                <Typography variant="subtitle1" color={"red"} component="div">
                                                    ({t('common.message.pleaseSelect')})
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                    <Box mt={1} pb={2}>
                                        <TimeSelection shop={state} deliverType={storeContext.deliverType} />
                                    </Box>
                                </Box>
                            }
                        </Card>
                        <Box>
                            <Box>
                                <PaymentChannel shop={state} />
                            </Box>
                            <CardContent>
                                <CartDiscountNotify onAddMore={handleBack} />
                                {state.payment && !(!state.payment.cash && !state.payment.fundsTransfer && !state.payment.qrPayment) &&
                                    <ConfirmOrder shop={state} />
                                }
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </>
            }
        </div>
    );
}
