import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import liff from "@line/liff";

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/c/login`;
    liff.login({ redirectUri: redirectUri });
};

export default function CustomerSignIn() {
    const history = useHistory();
    const { mode, key } = useParams();

    useEffect(() => {
        console.log('[CustomerSignIn]');
        const liffId = process.env.REACT_APP_LINE_LIFF_ID;
        liff.init({liffId: liffId, withLoginOnExternalBrowser: false}).then(r=>{
            lineAuthentication().then(r => {});
        });

    }, [history, mode, key]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20} />
            </Box>
        </div>
    )
}