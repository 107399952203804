import React from 'react';
import { createBrowserHistory } from 'history'
import Routes from './routes';
import './i18n/config';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Create your Own theme:
const theme = createTheme({
    palette: {
        primary: {
            main: '#06c755',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f5404c',
        },
        orange: {
            main: '#ffc72c',
            contrastText: '#000000',
        },
        success: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
        light: {
            main: '#ffffff'
        },
        default: {
            light: '#e0e0e0',
            main: '#8f8f8f',
            contrastText: '#000000',
        },
        grey: {
            dark: '#8f8f8f',
            main: '#afbfbf',
            contrastText: '#000000',
        },
    }
});

theme.typography.h4 = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};

const history = createBrowserHistory();
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    // <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Routes history={history} />
        </ThemeProvider>
    // </React.StrictMode>
);

// root.render(<div>OK</div>)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
